import resolveResponse from 'contentful-resolve-response'

import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ThemeProvider} from '@primer/react-brand'

import {cohortFunnelBuilder} from '../../../lib/analytics'
import {ZodSilentErrorBoundary} from '../../../components/ZodSilentErrorBoundary/ZodSilentErrorBoundary'
import {toPayload} from '../../../lib/types/payload'
import {toBrandPage, getBrandContentById} from '../../../brand/lib/types/contentful'

import FaqSection from './_components/FaqSection'
import FeaturesSection from './_components/FeaturesSection'
import FootnotesSection from './_components/FootnotesSection'
import HeroSection from './_components/HeroSection'
import PricingSection from './_components/PricingSection'
import ResourcesSection from './_components/ResourcesSection'

export default function NewFeaturesCopilotIndex() {
  const {contentfulRawJsonResponse} = toPayload(useRoutePayload<unknown>())
  const contentfulResponse = resolveResponse(contentfulRawJsonResponse)
  const page = toBrandPage(contentfulResponse)

  const {template} = page.fields
  const {subnav, content} = template.fields
  const faqSection = getBrandContentById({content, id: 'featuresCopilotFaqSection'})
  const faqComponent = faqSection?.fields.content?.find(
    item => item.sys.contentType.sys.id === 'primerComponentFaqGroup',
  )

  const {userHasOrgs} = useRoutePayload<{userHasOrgs: boolean}>()

  let {copilotProSignupPath} = useRoutePayload<{copilotProSignupPath: string}>()
  let {copilotForBusinessSignupPath} = useRoutePayload<{copilotForBusinessSignupPath: string}>()
  let {copilotEnterpriseSignupPath} = useRoutePayload<{copilotEnterpriseSignupPath: string}>()
  let {copilotBusinessContactSalesPath} = useRoutePayload<{copilotBusinessContactSalesPath: string}>()
  let {copilotEnterpriseContactSalesPath} = useRoutePayload<{copilotEnterpriseContactSalesPath: string}>()
  const {cft} = useRoutePayload<{cft: string}>()

  const withCft = cohortFunnelBuilder(cft)
  copilotProSignupPath = withCft(copilotProSignupPath, {product: 'cfi'})
  copilotForBusinessSignupPath = withCft(copilotForBusinessSignupPath, {product: 'cfb'})
  copilotEnterpriseSignupPath = withCft(copilotEnterpriseSignupPath, {product: 'ce'})
  copilotBusinessContactSalesPath = withCft(copilotBusinessContactSalesPath)
  copilotEnterpriseContactSalesPath = withCft(copilotEnterpriseContactSalesPath)
  const copilotPlansPath = withCft('/features/copilot/plans')

  return (
    <ZodSilentErrorBoundary>
      <ThemeProvider colorMode="dark" className="lp-Copilot">
        <HeroSection copilotPlansPath={copilotPlansPath} subnav={subnav} />

        <FeaturesSection />

        <PricingSection
          copilotProSignupPath={copilotProSignupPath}
          copilotForBusinessSignupPath={copilotForBusinessSignupPath}
          copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
          copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
          copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
          userHasOrgs={userHasOrgs}
          isExpanded
        />

        <ResourcesSection />

        {faqComponent ? <FaqSection contentfulContent={faqComponent} /> : null}

        <FootnotesSection />
      </ThemeProvider>
    </ZodSilentErrorBoundary>
  )
}

try{ NewFeaturesCopilotIndex.displayName ||= 'NewFeaturesCopilotIndex' } catch {}